import { useNetwork } from "@hooks/network";
import { Json } from "../../../../../types/common";
import { APIS } from "constant";
import { useCallback } from "react";
import { getJsonToParams } from "@utils/common";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AssetPortfolioState, OrderDetailsState } from "./state";
import { useNotification } from "@hooks/notification";
import { ActiveCurrencyState } from "@states/exchange";
import { assestType } from "@views/exchange/constants";

const UseOrdersApi = () => {
  const { get: getAllOrders, loading: AllOrderLoading } = useNetwork();
  const { get: getOrderDetails, statusCode: orderDetailsStatusCode } =
    useNetwork();
  const { get: getAssetPortfolio } = useNetwork();
  const { errorNotification } = useNotification();

  const setOrdersDetails = useSetRecoilState(OrderDetailsState);
  const setAssetPortfolio = useSetRecoilState(AssetPortfolioState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);


  const fetchOrders = useCallback(
    (query: Json = {}, activeTab: string, options?: any,setOrdersData?:any) => {
      if (!options.updateNewData) {
        setOrdersData({ loading: true, });
      }
      else{
        setOrdersData((prev: Json) => {
          return {
            ...prev,
            // [activeTab]: options.updateNewData === false ? [] : [...prev?.[activeTab]],
            loading: true,
          };
        });
      }
      const resData: Json = { loading: false };
      getAllOrders(APIS.ORDERS + getJsonToParams(query)).then((res: Json) => {
        if (res) {
          if (!options.updateNewData) {
            setOrdersData({ [activeTab]: [...res?.data] });
          } else {
            resData[activeTab] = res.data;
            setOrdersData((prev: any) => ({
              ...prev,
              loading: false,
              [activeTab]: [...prev[activeTab], ...res?.data],
            }));
          }
        }
      });
    },
    [getAllOrders]
  );
  const FetchOrderDetails = useCallback(
    async (id: string, options = {}) => {
      setOrdersDetails((prev: Json) => ({ ...prev, loading: true, data: {} }));

      const resData: Json = { loading: false };
      try {
        const res = await getOrderDetails(
          `${APIS.ORDERS}/${id}${getJsonToParams(options)}`
        );
        if (res) {
          if (res.message === "ok") {
            resData.data = res?.data;
            setOrdersDetails((prev: Json) => ({
              ...prev,
              ...resData,
            }));
          } else {
            errorNotification(res?.message);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    [errorNotification, getOrderDetails, setOrdersDetails]
  );

  const getUsersAssetPortfolio=useCallback((id:string)=>{
    if (
      activeCurrency?.type === assestType.privates ||
      activeCurrency?.type === assestType.SBA7 ||
      activeCurrency?.musicId
    ) {
      setAssetPortfolio(()=>{
        return {
          loading:true,
          data:{}
        }
      });
      getAssetPortfolio(`${APIS.ExchangePortfolio}/${id}`).then(
        (res) => {
          if (res) {
            setAssetPortfolio(()=>{
              return {
                loading:false, data:res?.data ? res.data : {}
              }
            });
          }
        }
      ).catch(()=>{
        setAssetPortfolio(() => ({
          loading: false,
          data: {}
        }));
      })
    } else {
      setAssetPortfolio(() => ({
        loading: false,
        data: {}
      }));
    }
  },[activeCurrency?.musicId, activeCurrency?.type, getAssetPortfolio, setAssetPortfolio])

  return {
    fetchOrders,
    orderDetailsStatusCode,
    FetchOrderDetails,
    AllOrderLoading,
    getUsersAssetPortfolio
  };
};

export default UseOrdersApi;
