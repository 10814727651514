interface IBaseAsset {
  logo: string;
  code: string;
  metaData: {};
}

interface IFile {
  uri: string;
  type: string;
  encrypted: boolean;
}

interface ICreator {
  label: string;
  address: string;
  share: number;
}

interface IProperties {
  files: IFile[];
  category: string;
  creators: ICreator[];
  signers: any[];
  whitelisted: any[];
}

interface IMetaData2 {
  name: string;
  symbol: string;
  description: string;
  image: string;
  type: string;
  memo: string;
  properties: IProperties;
}

interface ICounterAsset {
  logo?: string;
  name?: string;
  code: string;
  metaData?: IMetaData2;
}

export interface IPolygonData {
  transaction: {
    url: string;
    hash: string;
  };
  approval: {
    url: string;
    hash: string;
  };
}

export interface IOrderState {
  id?: string;
  pagingToken?: string;
  category: string;
  baseAsset?: IBaseAsset;
  counterAsset?: ICounterAsset;
  orderdate: number | string;
  quantity: number;
  price: number | string;
  completed: number;
  remaining: number;
  polygonData?: IPolygonData;
  totalamount: number;
  type: string;
  symbol: string;
  current_loan_balance: string;
  government_guaranteed_loan_balance: string,
}

export interface IOrdersState {
  openOrder: IOrderState[];
  executeOrder: IOrderState[];
}

export type IFormDataa = {
  symbol: string;
  type: string;
  price: string | number;
  limit?: string;
  qty: string | number;
};

export enum ModalType {
  CONFIRM,
  APPROVED,
  DECLINE,
  TIMEUP,
  DEFAULT,
  MODIFIED,
  CANCELLED
}
