import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import cn from "classnames";

import { ActiveCurrencyState, InfoState, userSelectedCurrencyState } from "states";
import { Form } from "./Form";

import styles from "./Actions.module.sass";
import { useCurrency } from "hooks/currency";
import { FortressAccountInfo } from "views/MyWallet/store/state";
import { UseTransaction } from "views/MyWallet/store/hooks";

const navigation = ["Limit", "Market", "Stop-limit"];

export const Actions = ({ isOrderModifying = false }) => {
  const activeCurrency = useRecoilValue(ActiveCurrencyState);

  const availableBalance = useRecoilValue<any>(FortressAccountInfo);
  const userSelectedCurrency = useRecoilValue(userSelectedCurrencyState);

  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleAction, setVisibleAction] = useState(false);
  const [visible, setVisible] = useState(false);
  const { getFortressAccountInfo } = UseTransaction();

  useEffect(() => {
    getFortressAccountInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { formatCurrency } = useCurrency();

  const handleClickBuy = useCallback(() => {
    setVisibleAction(true);
    setVisible(true);
  }, []);

  const handleClickSell = useCallback(() => {
    setVisibleAction(false);
    setVisible(true);
  }, []);

  const renderNav = useMemo(() => {
    return navigation.map((nav, index) => (
      <button
        className={cn(styles.link, {
          [styles.active]: index === activeIndex,
          [styles.disabled]: index !== 0,
        })}
        onClick={() => setActiveIndex(index)}
        key={index}>
        {nav}
      </button>
    ));
  }, [activeIndex]);

  const contentBuyVal = useMemo(() => {
    return `${formatCurrency(availableBalance?.balance ?? "0.00", 4)} `;
  }, [formatCurrency, availableBalance?.balance]);

  return (
    <div className={`${styles.actions} ${!activeCurrency?.symbol && styles.disabled}`} style={{}}>
      <>
        <div className={styles.head}>
          {isOrderModifying ? (
            <div className={styles.navbar}>Modify Order</div>
          ) : (
            // <div className={styles.nav}> {renderNav}</div>
            ""
          )}
        </div>
        <div className={cn(styles.wrapper, { [styles.show]: visible })}>
          {activeIndex === 0 && (
            <Form
              activeTabIndex={0}
              contentBuy={contentBuyVal}
              contentSell={contentBuyVal}
              visible={visibleAction}
              setVisible={setVisible}
              isOrderModifying={isOrderModifying}
            />
          )}
          {activeIndex === 1 && (
            <Form
              activeTabIndex={1}
              contentBuy={contentBuyVal}
              contentSell={contentBuyVal}
              visible={visibleAction}
              setVisible={setVisible}
              isOrderModifying={isOrderModifying}
            />
          )}
          {activeIndex === 2 && (
            <Form
              activeTabIndex={2}
              contentBuy={contentBuyVal}
              contentSell={contentBuyVal}
              limit={true}
              visible={visibleAction}
              setVisible={setVisible}
              isOrderModifying={isOrderModifying}
            />
          )}
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-green button-small", styles.button)}
            onClick={() => handleClickBuy()}>
            Buy
          </button>
          <button
            className={cn("button-red button-small", styles.button)}
            onClick={() => handleClickSell()}>
            Sell
          </button>
        </div>
      </>
    </div>
  );
};
