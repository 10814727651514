import type { IConfirmationModal } from "./type";

import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import useDarkMode from "use-dark-mode";

import { Loader, ReactModal } from "components";
import { CANCEL_ORDER_BTN } from "./constant";

import styles from "./buysellConfirmation.module.sass";

import cancelDark from "assets/images/cancel-dark.svg";
import cancelLight from "assets/images/cancel-light.svg";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { UsersLoadingState, userDetailsState, userPersonalDetails } from "@states/user";
import VerificationIncomplete from "../VerificationIncomplete/verificationIncomplete";
import classNames from "classnames";
import { useCurrency } from "@hooks/currency";
import { ActiveCurrencyState } from "@states/exchange";
import MpcIncomplete from "../MpcIncomplete/MpcIncomplete";
import MpcSetupModal from "../MpcSetupModal/MpcSetupModal";
import { FortressAccountInfo } from "@views/MyWallet/store/state";
import { useNavigate } from "react-router-dom";
import { DepositModalOpenState } from "@states/wallet";
import { ORDERBOOK_SERIES, assestType, gov_guaranteed_loan } from "@views/exchange/constants";
import { SelectedOrderState, VisibleModifyState } from "@states/Orders";
import NewModal from "@components/new-modal/new-modal";
import { UpgradeAccreditation } from "@views/exchange/components/Actions/Form/UpgradeAccreditation";
import { ACCREDITATION_TYPES, ACCREDITATION_ALLOWED } from "@views/exchange/components/Main/constants";
import { useNotification } from "@hooks/notification";
import InsufficientFunds from "@views/MyWallet/conponent/InsufficientFunds/insufficientFunds";

const BuysellConfirmation: FC<IConfirmationModal> = ({
  visible,
  title,
  description,
  handleModal,
  handleClose,
  boldDescription = "",
  label,
  cancelLoading = false,
  type,
  cancelLabel,
  formData,
}) => {
  const darkMode = useDarkMode(false);
  const [isKycDone, setIsKycDone] = useState(false);
  const [setupMpcWallet, setSetupMpcWallet] = useState(false);
  const [mpcIncomplete, setMpcIncomplete] = useState(false);
  const [openAccreditation, setOpenAccreditation] = useState(false);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const { formatCurrencyWithBillion, bondPointToAmount } = useCurrency();
  const isOrderBook = activeCurrency?.sbaType === ORDERBOOK_SERIES;
  const isGovGuarunteed = activeCurrency?.loanType === gov_guaranteed_loan;
  const selectedOrder = useRecoilValue(SelectedOrderState);
  const navigate = useNavigate()
  const setIsDepositModalOpen = useSetRecoilState<boolean>(
    DepositModalOpenState
  );
  const personalData = useRecoilValue(userPersonalDetails);
  const UserLoading = useRecoilValue(UsersLoadingState);
  const [isBalance,setIsBalance]= useState(false);
  const { fee, currentLoanBalance } = activeCurrency ?? {};
  const setVisibleModify = useSetRecoilState(VisibleModifyState);

  const [choosePaymentType, setChoosePaymentType] = useState("dvp");

  const userDetails = useRecoilValue(userDetailsState);
  const availableBalance = useRecoilValue<any>(FortressAccountInfo);

  const {errorNotification}=useNotification();
  const {
    accreditationDetails, onboardingData,isVerifiedEmail,
  } = userDetails?.data || {};

  const {
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    accreditationType,
  } = onboardingData || {};

  const isAnyStatusPending = [kycStatus, kybStatus, amlStatus].some(
    (status) =>
      status === "pending" || status === "processing" || status === "NA"
  );

  const isAnyStatusRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
  ].some((status) => status === "rejected");

  const userHasAccreditationAccess = useMemo(() => {
    const accreStatus = new Set(["rejected", "processing"]);
    const accreType = accreStatus.has(accreditationStatus || "") ? accreditationDetails?.previousType : accreditationType
    if (type !== "buy") return true
    if (activeCurrency?.accreditationType === ACCREDITATION_TYPES.C506 && accreditationType === ACCREDITATION_TYPES.C506 && accreditationStatus === "processing") return false
    else if (!!activeCurrency?.accreditationType && !!accreType)
      return !!ACCREDITATION_ALLOWED[accreType || ""]?.[activeCurrency?.accreditationType]
    return true
  }, [accreditationType, activeCurrency?.accreditationType]);

  const totalTradeAmt = useMemo(()=>{
    const tradeAmt = Number(formData?.price) * Number(formData?.qty);
    const transactionFee = (Number(formData?.price ?? 0) * (fee?.buy / 100) * Number(formData.qty));
    return tradeAmt + transactionFee;
  },[fee?.buy, formData?.price, formData.qty])
  

  useEffect(() => {
    if (
      !UserLoading &&
      (isAnyStatusPending ||
        isAnyStatusRejected ||
        (!kybStatus &&
          (accreditationStatus === "NA" || accreditationStatus === "pending")))
    ) {
      setIsKycDone(true);
      return;
    }
    // if (!newStatus?.isMpcWallet) {
    //   setMpcIncomplete(false);
    //   setSetupMpcWallet(true);
    //   return;
    // }
    if(!isVerifiedEmail){
      errorNotification("Please verify your email first")
      handleClose();
      return;
    }
    if ((!userDetails?.data?.isMPCWallet || ( type === "buy" && availableBalance?.balance === 0))) {
      setMpcIncomplete(true);
      return;
    }

    if((availableBalance?.balance < totalTradeAmt && type === "buy")) {
      setIsBalance(true);
      return;
    }

    if (!userHasAccreditationAccess && type === "buy" ) {
      setOpenAccreditation(true);
      return;
    }
    
  }, []);

  const { CANCEL_NO, CANCEL_YES } = CANCEL_ORDER_BTN;

  const handlePaymentTypeChange = useCallback((selectedPaymentType: string) => {
    setChoosePaymentType(selectedPaymentType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelMpcSetup = useCallback(() => {
    setIsKycDone(false);
    handleClose();
  }, []);

  const handleCloseAccreditation = useCallback(() => {
    setOpenAccreditation(false);
    if(!userHasAccreditationAccess) handleClose();
  }, [userHasAccreditationAccess]);

  const handleOpenMpcSetup = useCallback(() => {
    if (
      userDetails?.data?.isMPCWallet &&
      (!availableBalance || availableBalance?.balance === 0)
    ) {
      setIsDepositModalOpen(true);
      navigate("/wallet");
      return;
    }
    setSetupMpcWallet(true);
    setMpcIncomplete(false);
  }, []);



  const renderFee = useMemo(() => {

    if (formData) {
      let { price } = formData
      price = activeCurrency?.type === assestType.SBA7 && !isOrderBook ? Number(bondPointToAmount(currentLoanBalance, price).toFixed(2) || 0) : price
      const feesCharge = type === "buy" ? fee.buy : fee.sell;
      const tradeAmount = Number(price) * (Number(formData.qty));
      const transactionFee = (Number(price ?? 0) * (feesCharge / 100) * Number(formData.qty));
      const totaltax = Number(formData.qty) * transactionFee;
      const totalAmount = type === "buy" ? tradeAmount +  transactionFee : tradeAmount -  transactionFee;
      return (
        <div className={styles.totalAmountWrapper}>
          <div className={styles.totalAmount}>
            <div><span>Trade Amount</span><span>:</span><span>${tradeAmount.toFixed(2)}</span></div>
            <div><span>Transaction Fee</span><span>:</span><span>${transactionFee.toFixed(2)}</span></div>
            <div><span>Total</span><span>:</span><span>${totalAmount.toFixed(2)}</span></div>
          </div>
        </div>
      )
    }

  }, [activeCurrency?.type, bondPointToAmount, currentLoanBalance, fee.buy, fee.sell, formData, isOrderBook, type])

  const handleCloseInsuffBal = ()=>{
    setIsBalance(false)
    handleClose();
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      {userDetails?.data?.isMPCWallet &&
        (type === "buy" ? availableBalance && availableBalance?.balance !== 0 : true) &&
        !isKycDone &&
        !UserLoading && userHasAccreditationAccess && !(availableBalance?.balance < totalTradeAmt && type === "buy") && (
          <div className={styles.confirmationModal}>
            <div className={styles.confirmationHeader}>
              <img
                src={darkMode.value ? cancelDark : cancelLight}
                alt=""
                className={styles.confirmationImage}
              />
              <div className={styles.confirmationTitle}>{title}</div>
            </div>
            <div>
              <div className={styles.modalDes}>
                <span>
                  {description} <strong>{boldDescription}</strong>
                </span>
                {/* {personalData?.kyb && (
                  <div className={styles.paymentTypeContainer}>
                    <span>Choose Payment Type</span>
                    <div className={styles.paymentType}>
                      <div className={styles.paymentType__radioBtns}>
                        <input
                          id="primary"
                          type="radio"
                          value="DVP"
                          checked={choosePaymentType === "dvp"}
                          onChange={() => handlePaymentTypeChange("dvp")}
                          style={{
                            transform: "scale(1.5)",
                            marginTop: "5px",
                            cursor: "pointer",
                          }}
                        />
                        <p style={{ paddingTop: "2px" }}>DVP</p>
                      </div>
                      <div className={styles.paymentType__radioBtns}>
                        <input
                          id="primary"
                          type="radio"
                          value="Wallet"
                          checked={choosePaymentType === "wallet"}
                          onChange={() => handlePaymentTypeChange("wallet")}
                          style={{
                            transform: "scale(1.5)",
                            marginTop: "5px",
                            cursor: "pointer",
                          }}
                        />
                        <p style={{ paddingTop: "2px" }}>Wallet</p>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
              {/* {formData && <div className={styles.totalAmountWrapper}>
                <div className={styles.totalAmount}>
            <div><span>Trade Amount</span><span>:</span><span>${tradeAmount.toFixed(2)}</span></div>
            <div><span>Transaction Fee</span><span>:</span><span>${transactionFee.toFixed(2)}</span></div>
            <div><span>Total</span><span>:</span><span>${totalAmount.toFixed(2)}</span></div>
                </div>
              </div>} */}
              {formData && renderFee}
              <div className={styles.modalFooter}>
                <button
                  className={styles.buttonCancel}
                  onClick={handleClose}
                  disabled={cancelLoading}
                >
                  {cancelLabel ?? CANCEL_NO}
                </button>
                <button
                  className={`${styles.buttonCancelYes} ${
                    cancelLoading ? styles.loadingActive : ""
                  }  ${type === "buy" ? styles.btnbuy : ""}  ${
                    type === "success" ? styles.btnSuccess : ""
                  }`}
                  onClick={() => handleModal("wallet")}
                  disabled={cancelLoading}
                >
                  <span className={`${styles.btnText}`}>
                    {label ?? CANCEL_YES}
                  </span>
                  <i className={`${styles.loaderIcon}`}>
                    <Loader className="loader-white" dimension={26} />
                  </i>
                </button>
              </div>
            </div>
          </div>
        )}
      <ReactModal
        visible={isKycDone}
        onClose={() => {
          setIsKycDone(false);
          handleClose();
        }}
        closeBtn={true}
        maskClosable={false}
        outerClassName={classNames(
          styles.confirmationModalOuter,
          styles.kycStatusModal
        )}
      >
        <VerificationIncomplete
          setIsKycDone={setIsKycDone}
          text={"trades"}
          handleClosePreviousModal={handleClose}
        />
      </ReactModal>
      <ReactModal
        visible={mpcIncomplete}
        onClose={() => {
          setIsKycDone(false);
          handleClose();
        }}
        closeBtn={true}
        maskClosable={false}
        outerClassName={classNames(
          styles.confirmationModalOuter,
          styles.kycStatusModal
        )}
      >
        {mpcIncomplete && (
          <MpcIncomplete handleOpenMpcSetup={handleOpenMpcSetup} />
        )}
      </ReactModal>
      <ReactModal
        visible={setupMpcWallet}
        onClose={handleCancelMpcSetup}
        closeBtn={true}
        maskClosable={false}
        outerClassName={classNames(styles.MpcSetupStatusModal)}
      >
        <MpcSetupModal handleCancelMpcSetup={handleCancelMpcSetup} />
      </ReactModal>
      {openAccreditation && <NewModal
        isOpen={openAccreditation}
        closeModal={handleCloseAccreditation}
        modalName={""}
        showCloseBtn={true}
        isStopOutsideClick={false}
        closeOnEscBtn={false}
        overlayStyles={{ overlay: { zIndex: 1000, backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
        className={`new-react-modal accreditation-upgrade-modal`}>
        <UpgradeAccreditation onClose={handleCloseAccreditation} />
      </NewModal>}
      <ReactModal
        visible={isBalance}
        onClose={handleCloseInsuffBal}
        closeBtn={true}
        outerClassName={classNames(styles.confirmationModalOuter, styles.kycStatusModal)}
      >
        <InsufficientFunds
          fromAction={true}
          subHeading={"Please deposit funds to place an order."}
          setVisibleModify={setVisibleModify}
        />
      </ReactModal>
    </>
  );
};
export default BuysellConfirmation

