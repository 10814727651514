import React from 'react'
import './contact-us.scss';
import { Image } from '@storybook'

type Props = {}

export const ContactUs = (props: Props) => {
  return (
    <div className="contacy-us-container">
      <div className="contact_header">
        <div className="container_logo">
          <Image
            className="brand_logo"
            fileName={`images/Liquidity_Blue_Logo.png`}
          />
        </div>
        <div className="container_greeting">
          <h2>Contact Us</h2>
          <p>
            We're excited to connect with you! Whether you have inquiries, need
            assistance, or want to discuss potential collaborations, our team is
            here and ready to help.
          </p>
        </div>
      </div>

      <div className="container_info">
        <div className='info-panel'>
          <span className='info-icon'>
             <i className='ri-mail-line' />
          </span>
          <p className='info-type'>
            Email
          </p>
          <p className='info-link'>
            <a href="mailto:customercare.liquidity@liquidity.io">
              customercare.liquidity@liquidity.io
            </a>
          </p>
        </div>


        <div className='info-panel'>
          <span className='info-icon'>
             <i className='ri-phone-line' />
          </span>
          <p className='info-type'>
           Call us
          </p>
          <p className='info-link'>
            +1 310-968-7900
          </p>
        </div>
      </div>
    </div>
  );
}